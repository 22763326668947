/* App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: black;
}

.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 20px;
}

.App-header h1 {
  font-size: 3em;
  margin: 0;
  color: white;
  font-weight: bold;
}

.App-header h2 {
  font-size: 1.5em;
  margin: 0;
  font-weight: normal;
  color: #61dafb;
}

section {
  width: 80%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: white; /* Arka plan rengi beyaz */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  font-size: 2em;
  border-bottom: 2px solid #61dafb;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h4 {
  font-size: 1.5em;
  margin: 10px 0;
}

h5 {
  font-size: 1.2em;
  color: #555; /* Yazı rengi gri */
  margin: 5px 0;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin: 10px 0;
}

.education-item,
.experience-item {
  margin-bottom: 20px;
}

.experience-item ul {
  list-style-type: square;
}

/* Footer stili */
footer {
  padding: 10px;
  background-color: #282c34;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer p {
  margin: 10px 0;
}
